import { graphql } from "gatsby";
import React from "react";

import CenteredText from "../components/centered-text";
import Layout from "../components/layout";
import SEO from "../components/seo";

const ThankYou = ({ data: { avatar, backdrop, logo, site } }) => (
  <Layout avatar={avatar} backdrop={backdrop} site={site}>
    <SEO
      avatar={avatar}
      logo={logo}
      site={site}
      title={"She & Jim: Thank You!"}
    />
    <CenteredText>
      <h1>Thanks for your submission!</h1>
      <p>
        We appreciate you taking the time to reach out. Expect to hear back from
        us in the near future, and in the meantime, enjoy the rest of the blog!
      </p>
    </CenteredText>
  </Layout>
);

export const pageQuery = graphql`
  query ThankYouQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;

export default ThankYou;
